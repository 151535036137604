import React, { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { IMedia } from './models';
import './Media.scss';

const Media: FC<IMedia> = ({ children, video }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const modalWindow = video ? (
    <>
      <button
        className="dt-media-info-box__media-btn"
        type="button"
        aria-label="Play"
        onClick={handleShow}
      >
        <img src="/images/video-play.svg" alt="Play" width="82" height="82" />
      </button>

      <Modal
        dialogClassName="dt-media-info-box__media-modal"
        show={show}
        onHide={handleClose}
        centered
        size="xl"
      >
        <Modal.Body>
          <div className="dt-video-responsive-frame">
            <iframe
              width="1280"
              height="720"
              src={video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Modal Video"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" size="lg" onClick={handleClose}>
            Close video
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : null;

  return (
    <div className="dt-media-info-box__media">
      {children}
      {modalWindow}
    </div>
  );
};

export default Media;
